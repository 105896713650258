<template>
  <div>
    <div class="device-fixed" :class="{ fixed: deviceFixed, show: deviceFixedShow }" id="device-fixed">
      <b-row class="match-height">
        <b-col cols="12" xl="12">
          <b-card no-body>
            <b-card-body>
              <div class="device-top d-flex flex-column flex-xl-row align-items-xl-center align-items-start">
                <b-card-title class="text-device mb-0">
                  {{ device ? device.name || device.model_code  : ""}}
                </b-card-title>
                <div class="device-right">
                  <b-button class="device-btn" variant="none" data-id="01" @click="anchor($event)">
                    <feather-icon class="device-icon" icon="CheckSquareIcon" />
                    <span class="align-middle">{{ $t("Fit for Use") }}</span>
                  </b-button>
                  <b-button class="device-btn" variant="none" data-id="02" @click="anchor($event)">
                    <feather-icon class="device-icon" icon="CreditCardIcon" />
                    <span class="align-middle">{{ $t("Ambient") }}</span>
                  </b-button>
                  <b-button class="device-btn" variant="none" data-id="03" @click="anchor($event)">
                    <feather-icon class="device-icon" icon="TrendingUpIcon" />
                    <span class="align-middle">{{ $t("Performance") }}</span>
                  </b-button>
                  <b-button class="device-btn" variant="none" data-id="04" @click="goToAlerts">
                    <feather-icon class="device-icon" icon="BellIcon" />
                    <span class="align-middle">{{ $t("Alerts") }}</span>
                  </b-button>
                  <b-button class="device-btn" variant="none" @click="goToReports" data-id="05">
                    <feather-icon class="device-icon" icon="PieChartIcon" />
                    <span class="align-middle">{{ $t("Reports") }}</span>
                  </b-button>
                  <b-button class="device-btn mr-0" variant="none" data-id="06" @click="anchor($event)">
                    <feather-icon class="device-icon" icon="CheckSquareIcon" />
                    <span class="align-middle">{{ $t("Details") }}</span>
                  </b-button>
                  <v-select class="date-select" placeholder="day / week / month" :options="[
                    { value: 2, label: $t('2h') },
                    { value: 24, label: $t('24h') },
                    { value: 168, label: $t('7 days') },
                    { value: 720, label: $t('30 days') },
                  ]" v-model="timeInterval" :reduce="(time) => time.value" :searchable="false" />
                </div>
                <div class="device-info-wrapper relative d-flex">
                  <p class="serial-number">
                    {{ $t("Serial") }}:
                    <span>{{ device.serial_number }}</span>
                  </p>
                  <p class="model-number">
                    {{ $t("Model") }}:
                    <span>{{ device.model_code }}</span>
                  </p>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-modal v-model="showModal" id="modal-center" centered ok-title="OK!" class="justify-content-center"
      @ok="onCloseModal" ok-only v-on:close="onCloseModal" no-close-on-backdrop>
      <div class="modal-text">
        <span class="modal-title">{{ $t("Equipment's page") }}</span><br />
        <span class="modal-description">
          {{ $t("This is your equipment's page, here you will find all the information about this equipment only.")
          }}<br /><br />
          {{ $t("Browse through the menu and discover all the features.") }}<br /><br />
        </span>
      </div>
    </b-modal>
    <b-modal v-model="showModal_24" id="modal-last-24" centered ok-title="Subscribe" class="justify-content-center" ok-only>
      <div class="modal-text">
        <span class="modal-title">{{ $t("Last warning: your trial period is about to end.") }}</span><br />
        <span class="modal-description">
          {{ $t('Your trial period ends in 24 hours.') }}
          <br />
          {{ $t("Sign up now to continue enjoying all of Dialog's features!") }}
        </span>
      </div>
    </b-modal>
    <b-modal v-model="showModal_3days" id="modal-last-3days" centered ok-title="Subscribe" class="justify-content-center" ok-only>
      <div class="modal-text">
        <span class="modal-title">{{ $t("Your trial period ends soon.") }}</span><br />
        <span class="modal-description">
          {{ $t('The trial period ends in 3 days.') }}
          <br />
          {{ $t("Subscribe today to continue enjoying all of Dialog\'s features!") }}
        </span>
      </div>
    </b-modal>
    <b-modal v-model="showModal_week" id="modal-last-week" centered ok-title="Subscribe" class="justify-content-center"ok-only>
      <div class="modal-text">
        <span class="modal-title">{{ $t("Your trial period ends soon.") }}</span><br />
        <span class="modal-description">
          {{ $t('The trial period ends in one week.') }}
          <br />
          {{ $t("Subscribe today to continue enjoying all of Dialog\'s features!") }}
        </span>
      </div>
    </b-modal>
    <b-modal v-model="showModal_expired" id="modal-last-expired" centered ok-title="Go to Plans"
      class="justify-content-center" @ok="onCloseModal_sub" ok-only v-on:close="onCloseModal_sub" no-close-on-backdrop>
      <div class="modal-text">
        <span class="modal-title">{{ $t("Your 6 months trial period has ended.") }}</span><br />
        <span class="modal-description">
          {{ $t("Don't worry ") }} {{ currentUser.first_name }} {{ $t(", all your data is safe.") }}
          <br />
          {{ $t("It's not too late to continue one step ahead. Subscribe now to continue enjoying Dialog.") }}
        </span>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      deviceFixed: false,
      deviceFixedShow: false,
      goal_overview: {
        min_temp: 0,
        max_temp: 0,
      },
      showModal: false,
    };
  },
  computed: {
    imageS3() {
      return process.env.VUE_APP_S3_DEVICE;
    },
    device() {
      return this.$store.state.devices.currentDevice;
    },
    currentMetricGenericDevice() {
      return this.$store.state.devices.currentMetricGenericDevice;
    },
    timeInterval: {
      get() {
        return this.$store.state.devices.timeInterval;
      },
      set(data) {
        this.$store.commit("devices/SET_TIME_INTERVAL", data);
      },
    },
    currentUserShowModals() {
      return this.$store.state.users.currentUserShowModals
    },
    currentUser() {
      return this.$store.state.users.currentUser;
    },
    showModal_expired(){
      return this.$store.state.plans.ExpiredDetail.is_expired
    },
    showModal_3days(){
      return false
    },
    showModal_24(){
      return false
    },
    showModal_week(){
      return false
    }
  },
  watch: {
    timeInterval(newProp, oldProp) {
      this.getData();
    },
    currentUser(newProp, oldProp) {
      return this.verifyModal();
    },
  },
  methods: {
    setDeviceFixed(e) {
      this.deviceFixed = false;
      this.deviceFixedShow = false;
      let scroll = window.pageYOffset;
      let deviceHeight = document.getElementById("device-fixed")
        ? document.getElementById("device-fixed").offsetHeight
        : 0;
      if (scroll > deviceHeight) {
        this.deviceFixed = true;
        setTimeout(() => {
          this.deviceFixedShow = true;
        }, 300);
      } else if (scroll <= 2000 && this.deviceFixed) {
        this.deviceFixed = false;
        this.deviceFixedShow = false;
      }
    },
    goToAlerts() {
      this.$router.push({
        name: "device-alerts",
        params: { id: this.$route.params.id },
      });
    },
    goToReports() {
      this.$router.push({
        name: "device-reports",
        params: { id: this.$route.params.id },
      });
    },
    anchor(event) {
      let id = event.target.closest(".device-btn").dataset.id;
      if (id) {
        document.getElementById(id).scrollIntoView({ behavior: "smooth" });
      }
    },
    getData() {
      this.current_temp = this.currentMetricGenericDevice.current_internal_temp;
      this.goal_overview.min_temp =
        this.currentMetricGenericDevice.min_internal_temp;
      this.goal_overview.max_temp =
        this.currentMetricGenericDevice.max_internal_temp;
    },
    onCloseModal() {
      let data = { device_page: true }
      this.$store.dispatch("users/setPageView", { context: this, data, id: this.currentUserShowModals.id })
    },
    onCloseModal_sub() {
      this.$router.push({ name: 'apps-users-edit', params: { id: 1 } });
    },
    verifyModal() {
      this.$store.dispatch("users/getCurrentUserPages", { context: this })
        .then(() => {
          let currentUserShowModals = this.$store.state.users.currentUserShowModals
          if (currentUserShowModals) {
            this.showModal = !currentUserShowModals.device_page
          }
        })
    },
  },
  created() {
    this.$store.dispatch("plans/getVerifySubscription", {
      context: this,
      deviceId: this.$route.params.id,
    });
    this.getData();
    // this.verifyModal();
  },
  beforeMount() {
    window.addEventListener("scroll", this.setDeviceFixed);
  },
};
</script>
