<template>
  <b-card no-body>
    <b-card-body>
      <vue-apex-charts type="bar" height="400" :options="chartOptionsBar" :series="seriesBar" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BButtonGroup,
  BCardTitle,
  BCardSubTitle,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BButton,
    BButtonGroup,
    BCardTitle,
    BCardSubTitle,
    BBadge,
  },
  data() {
    return {
      seriesBar: [],
      chartOptionsBar: {
        chart: {
          type: "bar",
          height: 350,
          zoom: {
            enabled: true,
            type: "xy",
            resetIcon: {
              offsetX: -10,
              offsetY: 0,
              fillColor: "#fff",
              strokeColor: "#37474F",
            },
            selection: {
              background: "#90CAF9",
              border: "#0D47A1",
            },
          },
          toolbar: {
            show: true,
            position: "top",
            horizontalAlign: "right",
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              csv: {
                filename: "",
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: "",
              },
              png: {
                filename: "",
              }
            },
            autoSelected: 'zoom' 
          },
        },
        plotOptions: {
          bar: {
            distributed: true,
            horizontal: false,
            columnWidth: "50%",
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -25,
          formatter: function (val, index) {
            return val.toFixed(0) + " kWh";
          },
          style: {
            fontSize: "12px",
            colors: ["#fff"],
          },
        },
        colors: ["#2f79bb", "#4faef2"],
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          type: "category",
          categories: [],
          labels: {
            show: false
          }
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return val.toFixed(0) + " kWh";
            }
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {},
        legend: {
          show: false,
        },
        noData: {
          text: "No Data",
        },
      },
    };
  },
  computed: {
    timeInterval() {
      return this.$store.state.devices.timeInterval;
    },
    deviceTZ() {
      return this.$store.state.devices.currentDevice.timezone
        ? this.$store.state.devices.currentDevice.timezone
        : "Europe/Lisbon";
    },
    fileName(){
      let currentDevice = this.$store.state.devices.currentDevice
      let file_name = `ENERGY_CONSUMPTION_${currentDevice.model_code}-${currentDevice.name ? currentDevice.name : currentDevice.mac_number }`
      let options = { ...this.chartOptionsBar };
      options.chart.toolbar.export.csv.filename = file_name
      options.chart.toolbar.export.svg.filename = file_name
      options.chart.toolbar.export.png.filename = file_name
      this.chartOptionsBar = options;
    }
  },
  watch: {
    timeInterval(newProp, oldProp) {
      this.getData();
    },
    fileName(){}
  },
  methods: {
    async getData() {
      this.$store.commit("devices/SET_LOADING_STATE_START_GRAPH_BLAST_CHILL",'loading_energy_consumption');
      const series = [];
      const options = { ...this.chartOptionsBar };
      const deviceId = this.$route.params.id;
      const energyConsumptionEndpoint = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-energy-consumption/?device=${deviceId}&interval=${this.timeInterval}`;

      try {
        const res = await this.$http.get(energyConsumptionEndpoint);

        const { Chill, Freeze } = res.data;

        if (Chill === undefined && Freeze === undefined) {
          options.noData.text = "No Data";
          options.xaxis.categories = [];
          options.xaxis.labels.show = false;
        } else {
          const categories = ["Chill", "Freeze"];

          options.xaxis.labels.show = true;
          options.xaxis.categories = categories;

          series.push({
            name: this.$t("Energy Consumption"),
            data: [Chill, Freeze]
          });
        }
      } catch (error) {
        options.noData.text = "No Data";
        options.xaxis.categories = [];
        options.xaxis.labels.show = false;
      } finally {
        this.chartOptionsBar = options;
        this.seriesBar = series;
        this.$store.commit("devices/SET_LOADING_STATE_DONE_GRAPH_BLAST_CHILL",'loading_energy_consumption');
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>
