import axios from '@axios'

const eventsEndpoint = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-programs-used/`

const iconMapping = {
  "Chilling": "06_chilling_(white).svg",
  "Ice Cream hardening": "11_hardening_(white).svg",
  "Fish Sanitation": "13_sanitation_(white).svg",
  "Freezing": "07_freezing_(white).svg",
  "Proofing": "17_retarding_proofing_(white).svg",
  "Cooking": "05_cookbook_(white).svg",
  "Cooking & Chilling": "03_special_cycles_(white).svg",
  "Cooking & Holding": "03_special_cycles_(white).svg",
  "Manual Chilling": "09_manual_(white).svg",
  "Manual Chilling & Cooking": "09_manual_(white).svg",
  "Manual Chill Cook & hold": "09_manual_(white).svg",
  "Low load thawing": "16_thawing_(white).svg",
  "Medium load thawing": "16_thawing_(white).svg",
  "High load thawing": "16_thawing_(white).svg",
  "Cooling": "04_blast chiller_(white).svg",
  "Heating": "03_special_cycles_(white).svg",
  "Neutral zone": "03_special_cycles_(white).svg",
  "Chilling & cooking": "03_special_cycles_(white).svg",
  "Chilling, cooking & hold": "03_special_cycles_(white).svg",
  "Stand By": "03_special_cycles_(white).svg",
  "Pre-Cooling": "02_pre-cooling_(white).svg",
  "Sterilization": "15_sterilization_(white).svg",
  "Core Probe Heating": "14_needle_heater_(white).svg",
  "Drying": "12_drying_(white).svg",
};

const categoryMapping = {
  Special: ["Fish Sanitation", "Ice Cream hardening"],
  Holding: ["Cooking & Holding"]
};

const getCategoryByTitle = (title) => {
  const isSpecial = categoryMapping.Special.includes(title);
  const isHolding = categoryMapping.Holding.includes(title);

  if (isSpecial)
    return 'Special';
  else if (isHolding)
    return 'Holding';

  return 'Common';
};

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'common',
        label: 'Common',
      },
      {
        color: 'special',
        label: 'Special',
      },
      {
        color: 'holding',
        label: 'Holding',
      },
    ],
    selectedCalendars: ['Common', 'Special', 'Holding'],
    events: [],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val;
    },
    SET_EVENTS(state, val) {
      state.events = val;
    }
  },
  actions: {
    fetchEvents({ commit }, { deviceId, startDate, endDate }) {  //context -> for later adapt with interceptors
      //FILTERS
      let params = new URLSearchParams({
        device: deviceId,
        initial_date: startDate,
        end_date: endDate,
      });

      //GET DEVICES
      return new Promise((resolve, reject) => {
        axios.get(`${eventsEndpoint}?${params.toString()}`)
          .then(res => {
            const eventsRaw = res.data;
            const events = eventsRaw.map((event, index) => ({
              id: index,
              url: '',
              title: event.title,
              start: new Date(event.start).toISOString(),
              end: new Date(event.stop).toISOString(),
              allDay: false,
              extendedProps: {
                category: getCategoryByTitle(event.title),
                icon: iconMapping[event.title]
              }
            }));

            commit("SET_EVENTS", events)

            return resolve(events);
          }).catch(error => reject(error))
      });
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
