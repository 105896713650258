export default {
  scatterChart: {
    series: [
      {
        name: 'Energy Consumption',
        data: [
          [5.4, 15],
          [6.3, 10],
          [5.7, 12],
          [5.9, 7],
          [7.0, 5],
          [8.0, 14],
        ],
      },
    ],
    chartOptions: {
      chart: {
        zoom: {
          enabled: true,
          type: 'xy',
        },
        toolbar: {
          show: false,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      colors: ['#2f79bb'],
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'right',
      },
      xaxis: {
        // 
      },
      yaxis: {
        // opposite: isRtl,

      },
    },
  },
}