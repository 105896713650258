<template>
  <b-card no-body>
    <b-card-body>
      <vue-apex-charts type="line" height="350" :options="chartOptions" :series="series" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import envolventeSobreaquecChartData from "./envolventeSobreaquecChartData";
import moment from "moment-timezone";
export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      envolventeSobreaquecChartData,
      series: [],
      chartOptions: {
        chart: {
          zoom: {
            enabled: true,
            type: "xy",
            resetIcon: {
              offsetX: -10,
              offsetY: 0,
              fillColor: "#fff",
              strokeColor: "#37474F",
            },
            selection: {
              background: "#90CAF9",
              border: "#0D47A1",
            },
          },
          toolbar: {
            show: true,
            position: "top",
            horizontalAlign: "right",
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              csv: {
                filename: "",
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: "",
              },
              png: {
                filename: "",
              }
            },
            autoSelected: 'zoom' 
          },
        },
        markers: {
          strokeWidth: 1,
          strokeOpacity: 1,
          strokeColors: "#2f79bb",
          colors: "#2f79bb",
        },
        colors: ["#2f79bb", "#4faef2"],
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: "dd MMM HH:mm",
            formatter: undefined,
            labels: { datetimeUTC: false },
          },
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            format: "HH:mm",
            datetimeUTC: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return val.toFixed(0) + "ºC";
            },
          },
          tickAmount: 1,
        },
        noData: {
          text: "No Data",
        },
      },
    };
  },
  computed: {
    timeInterval() {
      return this.$store.state.devices.timeInterval;
    },
    deviceTZ() {
      return this.$store.state.devices.currentDevice.timezone
        ? this.$store.state.devices.currentDevice.timezone
        : "Europe/Lisbon";
    },
    fileName(){
      let currentDevice = this.$store.state.devices.currentDevice
      let file_name = `EVAPORATOR_SUPERHEATING_${currentDevice.model_code}-${currentDevice.name ? currentDevice.name : currentDevice.mac_number }`
      let options = { ...this.chartOptions };
      options.chart.toolbar.export.csv.filename = file_name
      options.chart.toolbar.export.svg.filename = file_name
      options.chart.toolbar.export.png.filename = file_name
      this.chartOptions = options;
    }
  },
  watch: {
    timeInterval(newProp, oldProp) {
      this.getData();
    },
    fileName(){}

  },
  methods: {
    getData() {
      this.$store.commit("devices/SET_LOADING_STATE_START_GRAPH",'loading_evaporator_overheating');
      this.series = [];
      const labelFormat = this.timeInterval > 24 ? 'dd/MM' : 'HH:mm';
      let deviceId = this.$route.params.id;
      const metricsEndpoint = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=condenser_efficacy_1`;
      const metricsEndpoint2 = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=condenser_efficacy_2`;

      this.$http.get(metricsEndpoint).then((res) => {
        let options = { ...this.chartOptions };
        let timestampCategories = res.data.categories.map((cat) => {
          return moment(new Date(cat)).tz(this.deviceTZ).format();
        });
        options.xaxis.categories = timestampCategories;
        options.xaxis.labels.format = labelFormat;
        this.chartOptions = options;
        //}

        this.series.push({
          name: this.$t("Inlet Temperature"),
          data: res.data.data.map((el) => {
            return Number(el.toFixed(2));
          }),
        });
      });

      this.$http.get(metricsEndpoint2).then((res) => {
        let options = { ...this.chartOptions };
        let timestampCategories = res.data.categories.map((cat) => {
          return moment(new Date(cat)).tz(this.deviceTZ).format();
        });
        options.xaxis.categories = timestampCategories;
        options.xaxis.labels.format = labelFormat;
        this.chartOptions = options;
        //}

        this.series.push({
          name: this.$t("Outlet Temperature"),
          data: res.data.data.map((el) => {
            return Number(el.toFixed(2));
          }),
        });
        this.$store.commit("devices/SET_LOADING_STATE_DONE_GRAPH",'loading_evaporator_overheating');
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>
