<template>
  <b-card no-body class="m-0">
    <b-card-body>
      <vue-apex-charts type="line" height="350" :options="chartOptions" :series="series" @click="clickHandler" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import envolventeChartData from "./envolventeChartData";
import moment from "moment-timezone";
export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      envolventeChartData,
      series: [],
      chartOptions: {
        chart: {
          zoom: {
            enabled: true,
            type: "xy",
            resetIcon: {
              offsetX: -10,
              offsetY: 0,
              fillColor: "#fff",
              strokeColor: "#37474F",
            },
            selection: {
              background: "#90CAF9",
              border: "#0D47A1",
            },
          },
          toolbar: {
            show: true,
            position: "top",
            horizontalAlign: "right",
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              csv: {
                filename: "",
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: "",
              },
              png: {
                filename: "",
              }
            },
            autoSelected: 'zoom' 
          },
        },
        markers: {
          strokeWidth: 1,
          strokeOpacity: 1,
          strokeColors: "#2f79bb",
          colors: "#2f79bb",
        },
        colors: ["#2f79bb", "#4faef2"],
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            format: "HH:mm",
            datetimeUTC: false,
          },
        },
        yaxis: [
          {
            title: {
              text: this.$t("Ambient Temperature"),
            },
            labels: {
              formatter: function (val, index) {
                return val.toFixed(0) + "ºC";
              },
            },
            tickAmount: 4,
            min: 20,
            max: 60,
          },
          {
            opposite: true,
            title: {
              text: this.$t("Ambient Humidity"),
            },
            labels: {
              formatter: function (val, index) {
                return val.toFixed(0) + "%";
              },
            },
            tickAmount: 4,
            min: 0,
            max: 100,
          },
        ],
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: "dd MMM HH:mm",
            formatter: undefined,
            labels: { datetimeUTC: false },
          },
        },
        noData: {
          text: "No Data",
        },
      },
    };
  },
  methods: {
    clickHandler(event, chartContext, config) {
      let dataToSend = {};
      for (let s of config.config.series) {
        dataToSend[s.name] = s.data[config.dataPointIndex];
      }
      this.$emit("updateBarsHumidityTemp", dataToSend);
    },
    getData() {
      this.$store.commit("devices/SET_LOADING_STATE_START_GRAPH_BLAST_CHILL",'loading_temp_amb_hum_rel');
      const labelFormat = this.timeInterval > 24 ? 'dd/MM' : 'HH:mm';
      this.series = [];
      let deviceId = this.$route.params.id;
      let metricsEndpoint = ''
      let metricsEndpointHumidade = ''

      if (this.timeInterval > 2) {
        metricsEndpointHumidade = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=env_humidity&window=15`;
        metricsEndpoint = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=env_temperature&window=15`;

      }
      else {
        metricsEndpointHumidade = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=env_humidity`;
        metricsEndpoint = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=env_temperature`;
      }


      let promisses = [];
      const p1 = this.$http.get(metricsEndpointHumidade).then((res) => {
        return {
          tipo: "humidade",
          dados: res.data,
        };
      });
      promisses.push(p1);

      const p2 = this.$http.get(metricsEndpoint).then((res) => {
        return {
          tipo: "temperatura",
          dados: res.data,
        };
      });
      promisses.push(p2);
      Promise.all(promisses).then((res) => {
        let temperature = res.filter((el) => el.tipo == "temperatura")[0];
        let humidity = res.filter((el) => el.tipo == "humidade")[0];

        let options = { ...this.chartOptions };
        let timestampCategories = temperature.dados.categories.map((cat) => {
          return moment(new Date(cat)).tz(this.deviceTZ).format();
        });
        options.xaxis.categories = timestampCategories;
        options.xaxis.labels.format = labelFormat;
        this.chartOptions = options;
        let last_data_AT = temperature.dados.data.at(-1);
        this.$store.commit(
          "devices/SET_DEVICE_STATUS_GRAPH_AT",
          last_data_AT,
          "ambient_temp_status"
        );
        this.series.push({
          name: "Ambient Temperature",
          data: temperature.dados.data.map((el) => {
            return Number(el.toFixed(2));
          }),
        });

        let optionsTemp = { ...this.chartOptions };
        let timestampCategoriesTemp = humidity.dados.categories.map((cat) => {
          return moment(new Date(cat)).tz(this.deviceTZ).format();
        });
        optionsTemp.xaxis.categories = timestampCategoriesTemp;
        this.chartOptions = optionsTemp;
        this.series.push({
          name: "Ambient Humidity",
          data: humidity.dados.data.map((el) => {
            return Number(el.toFixed(2));
          }),
        });
        this.$store.commit("devices/SET_LOADING_STATE_DONE_GRAPH_BLAST_CHILL",'loading_temp_amb_hum_rel');
      });
    },
  },
  computed: {
    timeInterval() {
      return this.$store.state.devices.timeInterval;
    },
    deviceTZ() {
      return this.$store.state.devices.currentDevice.timezone
        ? this.$store.state.devices.currentDevice.timezone
        : "Europe/Lisbon";
    },
    fileName(){
      let currentDevice = this.$store.state.devices.currentDevice
      let file_name = `AMBIENT_TEMPERATURE_RELATIVE_HUMIDITY_${currentDevice.model_code}-${currentDevice.name ? currentDevice.name : currentDevice.mac_number }`
      let options = { ...this.chartOptions };
      options.chart.toolbar.export.csv.filename = file_name
      options.chart.toolbar.export.svg.filename = file_name
      options.chart.toolbar.export.png.filename = file_name
      this.chartOptions = options;
    }
  },
  watch: {
    timeInterval(newProp, oldProp) {
      this.getData();
    },
    fileName(){}

  },
  created() {
    this.getData();
  },
};
</script>
