export default {
  scatterChart: {
    series: [
      {
        name: 'Energy Consumption',
        data: [
          [5.4, 50],
          [6.3, 30],
          [5.7, 20],
          [5.9, 35],
          [7.0, 40],
          [8.0, 45],
        ],
      },
    ],
    chartOptions: {
      chart: {
        zoom: {
          enabled: true,
          type: 'xy',
        },
        toolbar: {
          show: false,
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      colors: ['#2f79bb'],
      legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'right',
      },
      xaxis: {
        // 
      },
      yaxis: {
        labels: {
          formatter: function (val, index) {
            return val.toFixed(0) + " kWh/24 H";
          }
        },

      },
    },
  },
}