<template>
  <span>
    <span v-if="deviceTypology == 'Blast Chiller'">
      <abatedor />
    </span>
    <span v-else-if="checkIfNotBlastChiller">
      <generic-device />
    </span>
  </span>
</template>

<script>
import Abatedor from "./Abatedor.vue";
import GenericDevice from "../generic-device/GenericDevice.vue";

export default {
  components: {
    Abatedor, // Abatedor
    GenericDevice, // Outros
  },
  computed: {
    deviceTypology() {
      return this.$store.state.devices.currentDevice.typology;
    },
    checkIfNotBlastChiller() {
      let typology = this.$store.state.devices.currentDevice.typology;
      return !!(typology != 'Blast Chiller');
    },
  },
  created() {
    this.$store.dispatch("devices/getSingleDevice", {
      context: this,
      id: this.$route.params.id,
    });
    this.$store.dispatch("devices/getSingleDeviceRoles", {
      context: this,
      id: this.$route.params.id,
    });
  },
  beforeDestroy() {
    this.$store.commit("devices/CLEAR_CURRENT_DEVICE");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-device.scss";
</style>
