<template>
  <b-card no-body class="card-revenue-budget">
    <!-- chart -->
    <vue-apex-charts
      id="revenue-report-chart"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </b-card>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BButton,
  BCardText,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BProgress,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
import { kFormatter } from "@core/utils/filter";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import flatPickr from "vue-flatpickr-component";
import ChartjsComponentBarChart from "../charts-components/ChartjsComponentBarChart.vue";
import ChartjsComponentHorizontalBarChart from "../charts-components/ChartjsComponentHorizontalBarChart.vue";
import chartjsData from "../chartjsData";
import moment from "moment-timezone";

export default {
  components: {
    BCardCode,
    VueApexCharts,
    BButton,
    BCardText,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BDropdown,
    BDropdownItem,
    BProgress,
    StatisticCardWithAreaChart,
    flatPickr,
    ChartjsComponentBarChart,
    ChartjsComponentHorizontalBarChart,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartjsData,
      series: [],
      chartOptions: {
        chart: {
          zoom: {
            enabled: true,
            type: "x",
            resetIcon: {
              offsetX: -10,
              offsetY: 0,
              fillColor: "#fff",
              strokeColor: "#37474F",
            },
            selection: {
              background: "#90CAF9",
              border: "#0D47A1",
            },
          },
          stacked: true,
          type: "bar",
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#405082"],
        plotOptions: {
          bar: {
            columnWidth: "15px;",
            endingShape: "rounded",
          },
          distributed: true,
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: "#5b5b5b",
              fontSize: "0.86rem",
            },
            datetimeUTC: false,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          type: "datetime",
        },
        noData: {
          text: "No Data",
        },
      },
    };
  },
  computed: {
    timeInterval() {
      return this.$store.state.devices.timeInterval;
    },
    deviceTZ() {
      return this.$store.state.devices.currentDevice.timezone
        ? this.$store.state.devices.currentDevice.timezone
        : "Europe/Lisbon";
    },
  },
  watch: {
    timeInterval(newProp, oldProp) {
      this.getData();
    },
  },
  methods: {
    kFormatter,
    getData() {
      this.series = [];
      let deviceId = this.$route.params.id;
      const metricsEndpoint = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=condenser_efficacy_1`;
      const metricsEndpoint2 = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=condenser_efficacy_2`;

      let condenser_efficacy_1 = {};
      let condenser_efficacy_2 = {};
      let results = [];
      let options = { ...this.chartOptions };
      try {
        this.$http
        .get(metricsEndpoint)
        .then((res) => {
          condenser_efficacy_1 = res.data;
          return this.$http.get(metricsEndpoint2);
        })
        .then((res) => {
          condenser_efficacy_2 = res.data;
          for (let index in condenser_efficacy_1.data) {
            results.push(
              condenser_efficacy_1.data[index] -
                condenser_efficacy_2.data[index]
            );
          }

          
          let timestampCategories = condenser_efficacy_2.categories.map(
            (cat) => {
              return moment(new Date(cat)).tz(this.deviceTZ).format();
            }
          );
          options.xaxis.categories = timestampCategories;
          this.chartOptions = options;
          //}

          this.series.push({
            name: "Intervalo de temperatura do gás à entrada e à saida",
            data: results.map((el) => {
              return Number(el.toFixed(2));
            }),
          });
        });
      } catch (error) {
        options.noData.text = "No Data";
        options.xaxis.categories = [];
        options.xaxis.labels.show = false;
        
      }

    },
  },
  created() {
    this.getData();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>