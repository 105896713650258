<template>
  <div>
    <b-row>
      <b-col cols="12" xl="4">
        <b-card no-body>
          <b-card-body>
            <div class="device-img-abatedor">
              <img :src="`${imageS3}${device.image}`" alt="" />
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="12" xl="8">
        <b-card no-body class="alerts-status-abatedor">
          <b-card-body style="padding-bottom: 10px;">
            <b-row>
<!-- Cabinet internal temp -->
              <b-col xl="3" class="status-side-title">
                <b-card-title class="text-status mb-0">{{ $t("Cabinet internal temp.") }}
                </b-card-title>
                <div class="alert-device-icons">
                  <div class="alert-icon-device row" style="margin: 0 0 20px 0">
                    <img src="@/assets/images/icons/temperature.png" alt="" class="big" />
                    <span class="alert-device-text big">{{ validateCycle(currentMetricBlastChiller.cycle_type_status, 1) ?
                      currentMetricBlastChiller.internal_temp : '-' }} ºC</span>
                  </div>
                </div>
                <div class="temp-range input">
                  <span style="font-size: 20px"> {{ $t("Target") }}</span>
                  <input type="text" readonly style="cursor: default"
                    :value="validateCycle(currentMetricBlastChiller.cycle_type_status, 1) && isValueValid(currentMetricBlastChiller.internal_temp_set) ? currentMetricBlastChiller.internal_temp_set : '-'" />
                  <span style="font-size: 20px">ºC</span>
                </div>
              </b-col>
<!-- Need prob temp -->
              <b-col class="status-side" xl="3">
                <b-card-title class="text-status mb-0">{{ $t("Need prob temp.") }}
                </b-card-title>
                <div class="alert-device-icons">
                  <div class="alert-icon-device row" style="margin: 0 0 20px 0">
                    <img src="@/assets/images/icons/temperature02.png" alt="" class="big" />
                    <span class="alert-device-text big">{{ validateCycle(currentMetricBlastChiller.cycle_type_status, 0) ?
                      currentMetricBlastChiller.probe_temp : '-' }} ºC</span>
                  </div>
                </div>
                <div class="temp-range input">
                  <span style="font-size: 20px"> {{ $t("Target") }}</span>
                  <input type="text" readonly style="cursor: default"
                    :value="validateCycle(currentMetricBlastChiller.cycle_type_status, 0) && isValueValid(currentMetricBlastChiller.probe_temp_set) ? currentMetricBlastChiller.probe_temp_set : '-'" />
                  <span style="font-size: 20px"> ºC</span>
                </div>
              </b-col>
<!-- Cycle time -->
              <b-col class="status-side" xl="3">
                <b-card-title class="text-status mb-0">{{ $t("Cycle Time") }}
                </b-card-title>
                <div class="alert-device-icons">
                  <div style="position: relative">
                    <div class="graph-legend-inside-abatedor white" v-if="currentMetricBlastChiller.cycle_time !== -32764">
                      {{ currentMetricBlastChiller.cycle_time }}<span>min</span>
                    </div>
                    <div class="graph-legend-inside-abatedor white" v-else>
                      -
                      <span>min</span>
                    </div>
                    <vue-apex-charts type="pie" width="200" :options="goalOverviewRadialBar" :series="series" />
                  </div>
                </div>
              </b-col>
<!-- Device Status -->
              <b-col class="status-side last" xl="3">
                <b-card-title class="text-status mb-0">{{ $t("Device Status") }}
                </b-card-title>
                <div class="alert-device-icons">
                  <div class="alert-icon-device" style="display: block;
margin: 20px auto;">
                    <img v-if="device.status === 'OK!'" src="@/assets/images/icons/ok.png" alt="" />
                    <img v-else-if="device.status == 'NOK!'" src="@/assets/images/icons/nok.png" alt="" />
                    <img v-else-if="device.status == 'Ups!'" src="@/assets/images/icons/ups.png" alt="" />
                    <span class="alert-device-text">{{ device.status }}</span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    VueApexCharts,
    vSelect,
  },
  directives: {
    Ripple,
  },
  computed: {
    imageS3() {
      return process.env.VUE_APP_S3_DEVICE;
    },
    device() {
      return this.$store.state.devices.currentDevice;
    },
    currentMetricGenericDevice() {
      return this.$store.state.devices.currentMetricGenericDevice;
    },
    timeInterval: {
      get() {
        return this.$store.state.devices.timeInterval;
      },
      set(data) {
        this.$store.commit("devices/SET_TIME_INTERVAL", data);
      },
    },
    recipes() {
      return this.$store.state.recipes.recipes;
    },
    currentMetricBlastChiller() {
      return this.$store.state.devices.currentMetricBlastChiller;
    },
  },
  data() {
    return {
      show_temps: false,
      show_img_recipe: false,
      recipe_name: "",
      recipe_img: "",
      goal_overview: {
        min_temp: 0,
        max_temp: 0,
      },
      current_temp: 0,
      series: [100, 0],
      goalOverviewRadialBar: {
        theme: {
          monochrome: {
            enabled: true,
          },
        },
        plotOptions: {
          pie: {
            dataLabels: false,
          },
        },
        fill: {
          colors: ["#90CAF9", "#4faef2"],
        },
        legend: {
          show: false,
        },
      },
    };
  },
  watch: {
    timeInterval(newProp, oldProp) {
      this.getData();
    },
    'currentMetricBlastChiller.cycle_time_total'(newProp, oldProp) {
      this.getData();
    }
  },
  methods: {
    goToAlerts() {
      this.$router.push({
        name: "device-alerts",
        params: { id: this.$route.params.id },
      });
    },
    goToReports() {
      this.$router.push({
        name: "device-reports",
        params: { id: this.$route.params.id },
      });
    },
    anchor(event) {
      let id = event.target.closest(".device-btn").dataset.id;
      if (id) {
        document.getElementById(id).scrollIntoView({ behavior: "smooth" });
      }
    },
    getPercentCycleTime(remaining_time, total_time) {
      const elapsedTime = total_time - remaining_time;

      return (elapsedTime/total_time*100).toFixed(2);
    },
    getData() {
      const percentCycleTime = this.getPercentCycleTime(
        this.currentMetricBlastChiller.cycle_time,
        this.currentMetricBlastChiller.cycle_time_total
      );

      this.series = [parseFloat(percentCycleTime), 100 - percentCycleTime];
    },
    getRecipe() {
      this.cleanData()
      let last_updated_recipe = Number(
        this.currentMetricGenericDevice.current_recipe
      ).toFixed(0);
      if (last_updated_recipe === 200) last_updated_recipe = 1;
      let recipe_obj = this.recipes.find((x) => x.code == last_updated_recipe);
      this.recipe_name = recipe_obj ? recipe_obj.name : this.$t("Default");
      this.recipe_img = recipe_obj ? recipe_obj.image : "";
      this.show_img_recipe = true;
      this.goal_overview.min_temp = recipe_obj ? recipe_obj.min_temp : "";
      this.goal_overview.max_temp = recipe_obj ? recipe_obj.max_temp : "";
      this.show_temps = recipe_obj ? true : false;
    },
    cleanData() {
      this.recipe_name = this.$t("None");
      this.recipe_img = "";
      this.show_img_recipe = false;
      this.goal_overview.min_temp = "";
      this.goal_overview.max_temp = "";
      this.show_temps = false;
    },
    validateCycle(cycle, type) {
      return cycle === type;
    },
    isValueValid(value) {
      return ![32767, -32764].includes(value);
    },
  },
  created() {
    this.$store.dispatch("recipes/getRecipes", { context: this }).then(() => {
      this.getRecipe();
      this.getData();
    });
  },
};
</script>
