<template>
  <b-card
      no-body
      class="card-revenue-budget"
  >
    <!-- chart -->
    <vue-apex-charts
    id="revenue-report-chart"
    height="230"
    :options="revenueReport.chartOptions"
    :series="revenueReport.series"
    />
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BButton, BCardText, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BDropdown, BDropdownItem, BProgress
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import flatPickr from 'vue-flatpickr-component'
import ChartjsComponentBarChart from '../charts-components/ChartjsComponentBarChart.vue'
import ChartjsComponentHorizontalBarChart from '../charts-components/ChartjsComponentHorizontalBarChart.vue'
import chartjsData from '../chartjsData'

export default {
  components: {
    BCardCode,
    VueApexCharts,
    BButton,
    BCardText,
    BRow,
    BCol,
    BCard, 
    BCardHeader, 
    BCardTitle, 
    BCardBody, 
    BCardText, 
    BDropdown, 
    BDropdownItem,
    BProgress,
    StatisticCardWithAreaChart,
    flatPickr,
    ChartjsComponentBarChart,
    ChartjsComponentHorizontalBarChart,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartjsData,
      revenueReport: {
        series: [
          {
            name: 'Temperatura',
            data: [95, 177, 284, 256, 105, 95, 177, 284, 256, 105, 95, 177, 284, 256, 105],
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          dataLabels: {
            enabled: false,
          },
          colors: ['#405082'],
          plotOptions: {
            bar: {
              columnWidth: '20px;',
              endingShape: 'rounded',
            },
            distributed: true,
          },
          xaxis: {
            categories: ['', '', '15:00h', '', ],
            labels: {
              style: {
                colors: '#5b5b5b',
                fontSize: '0.86rem',
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
        },
      },
    }
  },
  created() {
    this.$http.get('/card/card-analytics/revenue-report')
        .then(res => { this.revenue_report = res.data })
  },
  methods: {
    kFormatter,
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>