<template>
  <b-card no-body>
    <b-card-body>
      <vue-apex-charts type="scatter" height="400" :options="chartOptions" :series="series" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BButtonGroup,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
import performaceEnergChartData from "./performaceEnergChartData";
import moment from "moment-timezone";
export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BButton,
    BButtonGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      performaceEnergChartData,
      series: [],
      chartOptions: {
        chart: {
          zoom: {
            enabled: true,
            type: "xy",
            resetIcon: {
              offsetX: -10,
              offsetY: 0,
              fillColor: "#fff",
              strokeColor: "#37474F",
            },
            selection: {
              background: "#90CAF9",
              border: "#0D47A1",
            },
          },
          toolbar: {
            show: true,
            position: "top",
            horizontalAlign: "right",
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              csv: {
                filename: "",
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: "",
              },
              png: {
                filename: "",
              }
            },
            autoSelected: 'zoom' 
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: "dd MMM HH:mm",
            formatter: undefined,
            labels: { datetimeUTC: false },
          },
        },
        colors: ["#2f79bb"],
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            format: "HH:mm",
            datetimeUTC: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return val.toFixed(0) + " min.";
            },
          },
        },
        noData: {
          text: this.$t("No data for table"),
        },
      },
    };
  },
  computed: {
    timeInterval() {
      return this.$store.state.devices.timeInterval;
    },
    deviceTZ() {
      return this.$store.state.devices.currentDevice.timezone
        ? this.$store.state.devices.currentDevice.timezone
        : "Europe/Lisbon";
    },
    fileName(){
      let currentDevice = this.$store.state.devices.currentDevice
      let file_name = `EVAPORATOR_DEFROST_${currentDevice.model_code}-${currentDevice.name ? currentDevice.name : currentDevice.mac_number }`
      let options = { ...this.chartOptions };
      options.chart.toolbar.export.csv.filename = file_name
      options.chart.toolbar.export.svg.filename = file_name
      options.chart.toolbar.export.png.filename = file_name
      this.chartOptions = options;
    }
  },
  watch: {
    timeInterval(newProp, oldProp) {
      this.getData();
    },
    fileName(){}
  },
  methods:
  {
    getData() {
      this.$store.commit("devices/SET_LOADING_STATE_START_GRAPH",'loading_defrost_equipment_3');
      const labelFormat = this.timeInterval > 24 ? 'dd/MM' : 'HH:mm';
      let deviceId = this.$route.params.id;
      let intervalTime = ""
      switch (this.timeInterval) {
        case 2:
          intervalTime = "2h"
          break;
        case 24:
          intervalTime = "24h"
          break;
        case 168:
          intervalTime = "last_week"
          break;
        case 720:
          intervalTime = "last_month"
          break;
        default:
          intervalTime = "2h"
          break;
      }
     
      const metricsEndpoint = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-processed-defrost-data/?device=${deviceId}&interval=${intervalTime}&metric_field=defrost_equipment_1`;
      this.$http.get(metricsEndpoint).then((res) => {
        let options = { ...this.chartOptions };
        let timestampCategories = res.data.categories.map((cat) => {
          return moment(new Date(cat)).tz(this.deviceTZ).format();
        });
        options.xaxis.categories = timestampCategories;
        options.xaxis.labels.format = labelFormat;

        this.chartOptions = options;
        //}

        this.series.push({
          name: this.$t("Duration"),
          data: res.data.data.map((el) => {
            let num = el / 60;
            return Number(num.toFixed(2));
          }),
        });

        if (res.data.categories.length == 0) {
          this.chartOptions.noData.text = this.$t("No defrost cycles found");
        }
      });
      this.$store.commit("devices/SET_LOADING_STATE_DONE_GRAPH",'loading_defrost_equipment_3');

    }
  },
  created() {
    this.getData();

  },
};
</script>