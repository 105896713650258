<template>
  <b-card no-body>
    <b-card-body>
      <vue-apex-charts type="line" height="350" :options="chartOptions" :series="series" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import adequacaoChartData from "./adequacaoChartData";
import moment from "moment-timezone";
export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      adequacaoChartData,
      series: [],
      chartOptions: {
        chart: {
          zoom: {
            enabled: true,
            type: "x",
            resetIcon: {
              offsetX: -10,
              offsetY: 0,
              fillColor: "#fff",
              strokeColor: "#37474F",
            },
            selection: {
              background: "#90CAF9",
              border: "#0D47A1",
            },
          },
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "right",
        },
        // markers: {
        //   strokeWidth: 1,
        //   strokeOpacity: 1,
        //   strokeColors: '#2f79bb',
        //   colors: '#2f79bb',
        // },
        colors: ["#2f79bb", "transparent"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: "dd MMM HH:mm",
            formatter: undefined,
            labels: { datetimeUTC: false },
          },
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            format: "HH:mm",
            datetimeUTC: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              return val.toFixed(0) + " %";
            },
          },
        },
        noData: {
          text: "No Data",
        },
      },
    };
  },
  computed: {
    timeInterval() {
      return this.$store.state.devices.timeInterval;
    },
    deviceTZ() {
      return this.$store.state.devices.currentDevice.timezone
        ? this.$store.state.devices.currentDevice.timezone
        : "Europe/Lisbon";
    },
  },
  watch: {
    timeInterval(newProp, oldProp) {
      this.getData();
    },
  },
  methods: {
    getData() {
      let deviceId = this.$route.params.id;
      const labelFormat = this.timeInterval > 24 ? 'dd/MM' : 'HH:mm';
      const metricsEndpoint = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=internal_humidity`;

      this.$http.get(metricsEndpoint).then((res) => {
        let options = { ...this.chartOptions };
        let timestampCategories = res.data.categories.map((cat) => {
          return moment(new Date(cat)).tz(this.deviceTZ).format();
        });
        options.xaxis.categories = timestampCategories;
        options.xaxis.labels.format = labelFormat;
        this.chartOptions = options;
        this.series = [
          {
            data: res.data.data.map((el) => {
              return Number(el.toFixed(0));
            }),
          },
        ];
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>