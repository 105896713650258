<template>
  <b-row class="abertura-portas">
    <b-col lg="2">
      <div class="temp-left">
        <span class="img-icon-door">
          <img src="@/assets/images/icons/open-door.png" alt="" />
        </span>
        <span class="text-int"> {{ $t("Doors Opening") }} </span>
      </div>
    </b-col>
    <b-col lg="3" class="portas-text">
      <div class="portas-text">
        <span :class="comparisonClasses"
          >{{
            doorOpenTotalCompare >= 0
              ? `+${doorOpenTotalCompare}`
              : `${doorOpenTotalCompare}`
          }}
          un.</span
        >
        <div class="portas-text-middle">
          <span class="portas-mid-text">{{ doorOpenTotal }}</span>
          <img
            v-if="doorOpenTotalCompare > 0"
            src="@/assets/images/icons/consumo-red.png"
            alt=""
          />
          <img v-else src="@/assets/images/icons/consumo-green.png" alt="" />
        </div>
        <span class="portas-bottom-text">{{ $t("Doors Opening") }}</span>
      </div>
    </b-col>
    <b-col lg="3" class="portas-text">
      <div class="portas-text">
        <span :class="comparisonClassesTime"
          >{{
            doorOpenTotalTimeCompareSec >= 0
              ? `+${doorOpenTotalTimeMinCompare}`
              : `-${doorOpenTotalTimeMinCompare}`
          }}
          </span
        >
        <div class="portas-text-middle">
          <span class="portas-mid-text">
            {{ doorOpenTotalTimeMin }}
          </span>
          <img
            v-if="doorOpenTotalTimeCompareSec > 0"
            src="@/assets/images/icons/consumo-red.png"
            alt=""
          />
          <img v-else src="@/assets/images/icons/consumo-green.png" alt="" />
        </div>
        <span class="portas-bottom-text">{{ $t("Total Time (hh:mm:ss)") }}</span>
      </div>
      <!-- <div class="info-btn">
        <b-button
          class="btn-sm temp-info-btn"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.left="
            $t(
              'Total cost and time that the conservation mode was active after completing a cycle. to max. efficiency reduces this value.'
            )
          "
          variant="none"
        >
          <feather-icon class="temp-info-icon" icon="AlertCircleIcon" />
        </b-button>
      </div> -->
    </b-col>
    <b-col lg="3" class="portas-text">
      <div class="portas-text">
        <span :class="comparisonClassesCost"
          >{{
            additionalCostsCompare >= 0
              ? `+${additionalCostsCompare.toLocaleString()}`
              : `${additionalCostsCompare.toLocaleString()}`
          }}
          {{(deviceCurrency ? deviceCurrency : "EUR")}}</span
        >
        <div class="portas-text-middle">
          <span class="portas-mid-text">
            {{ doorOpenTotal == 0 ? "0,00" : additionalCosts }}
          </span>
          <img
            v-if="additionalCostsCompare > 0"
            src="@/assets/images/icons/consumo-red.png"
            alt=""
          />
          <img v-else src="@/assets/images/icons/consumo-green.png" alt="" />
        </div>
        <span class="portas-bottom-text">{{ $t("Additional costs") }}</span>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
  BRow,
  BCol,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BButton,
    VBTooltip,
  },
  data() {
    return {
      doorOpenTotal: 0,
      doorOpenTotalTimeMin: "00:00:00",
      doorOpenTotalTimeSec: "00",

      doorOpenTotalCompare: 0,
      doorOpenTotalTimeCompareSec: 0, // Non formated time
      doorOpenTotalTimeMinCompare: "00:00:00",
      doorOpenTotalTimeSecCompare: "00",
      
      additionalCosts:0,
      additionalCostsCompare:0
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  methods: {
    msToTimeMin(s) {
      s = Math.abs(s);
      let secs = s % 60 < 10 ? `0${s % 60}` : s % 60;
      s = (s - secs) / 60;
      let mins = s % 60 < 10 ? `0${s % 60}` : s % 60;
      let hrs = (s - mins) / 60;
      return hrs + ":" + mins + ":" + secs;
    },
    msToTimeSec(s) {
      let ms = s % 1000;
      s = (s - ms) / 1000;
      return s % 60;
    },
    getData() {
      let deviceId = this.$route.params.id;
      const metricsEndpoint = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=door_open_count`;
      this.$http.get(metricsEndpoint).then((res) => {
        //Total de abertura de portas no periodo de tempo
        if (res.data.data.length > 0) {
          let openCount =
            res.data.data[res.data.data.length - 1] - res.data.data[0];
          this.doorOpenTotal = Math.ceil(openCount);
        }
      });

      const metricsEndpoint2 = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=door_open_time`;
      this.$http.get(metricsEndpoint2).then((res) => {
        //Total de tempo de portas abertas no periodo de tempo
        if (res.data.data.length > 0) {
          this.doorOpenTotalTimeMin = this.msToTimeMin(
            (
              res.data.data[res.data.data.length - 1] - res.data.data[0]
            ).toFixed(0)
          );

          this.doorOpenTotalTimeSec = this.msToTimeSec(
            res.data.data[res.data.data.length - 1] - res.data.data[0]
          );
        }
      });

      let compareTimeInterval = ''
      switch (this.timeInterval) {
        case 2:
          compareTimeInterval = "2h"
          break;
        case 24:
          compareTimeInterval = "24h"
          break;
        case 168:
          compareTimeInterval = "last_week"
          break;
        case 720:
          compareTimeInterval = "last_month"
          break;
        default:
          compareTimeInterval = "2h"
          break;
      }

      const metricsEndpoint3 = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-period-comparison/?device=${deviceId}&interval=${compareTimeInterval}&metric_field=door_open_count`;
      this.$http.get(metricsEndpoint3).then((res) => {
        //Total de tempo de portas abertas no periodo de tempo
        this.doorOpenTotalCompare =
          res.data.closerPeriod - res.data.distantPeriod;
      });

      const metricsEndpoint4 = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-period-comparison/?device=${deviceId}&interval=${compareTimeInterval}&metric_field=door_open_time`;
      this.$http.get(metricsEndpoint4).then((res) => {
        //Total de tempo de portas abertas no periodo de tempo
        let doorOpenTotalTimeCompare =
          res.data.closerPeriod - res.data.distantPeriod;
        this.doorOpenTotalTimeCompareSec = doorOpenTotalTimeCompare;
        this.doorOpenTotalTimeMinCompare = this.msToTimeMin(
          doorOpenTotalTimeCompare
        );

        this.doorOpenTotalTimeSecCompare = this.msToTimeSec(
          doorOpenTotalTimeCompare
        );
      });

      const metricsEndpoint5 = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-period-comparison/?device=${deviceId}&interval=${compareTimeInterval}&metric_field=energy_consumption_all`;
      this.$http.get(metricsEndpoint5).then((res) => {
        if (this.energyCost > 0) {
          this.additionalCosts = ((res.data.closerPeriod / 1000) * this.energyCost).toLocaleString("default", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          this.additionalCostsCompare = (((res.data.closerPeriod -  res.data.distantPeriod)/ 1000) * this.energyCost).toLocaleString("default", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        }
      });
    },
  },
  computed: {
    energyCost()
    {
      return this.$store.state.devices.currentDevice.energy_price;
    },
    timeInterval() {
      return this.$store.state.devices.timeInterval;
    },
    comparisonClasses() {
      return `portas-top-text ${this.doorOpenTotalCompare <= 0 ? "green" : ""}`;
    },
    comparisonClassesTime() {
      return `portas-top-text ${
        this.doorOpenTotalTimeCompareSec <= 0 ? "green" : ""
      }`;
    },
    comparisonClassesCost() {
      return `portas-top-text ${
        this.additionalCostsCompare < 0 ? "red" : "green"
      }`;
    },
    deviceCurrency(){
        return this.$store.state.devices.currentDevice.currency;
    }
  },
  watch: {
    timeInterval(newProp, oldProp) {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
};
</script>
