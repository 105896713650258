<template>
  <div id="03">
    <b-row class="match-height">
      <b-col md="12" lg="12">
        <b-card no-body class="performace-device">
          <b-card-body>
            <div class="performace-top">
              <span class="performace-icon">
                <feather-icon icon="TrendingUpIcon" />
              </span>
              <span class="text-performace"> {{ $t("Performance") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <b-card class="section-temp">
          <b-row>
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon-energy">
                  <img src="@/assets/images/icons/energy.png" alt="" />
                </span>
                <span class="text-int"> {{ $t("Energy Consumption") }} </span>
              </div>
            </b-col>
            <b-col lg="6">
              <!-- <span class="graph-dot-blue"></span>
              <span class="graph-legend">{{ $t("Energy Consumption") }}</span> -->
              <device-info-performace-cons-energ />
            </b-col>
            <b-col lg="3" class="kwh-right">
              <div class="
                    text-legenda
                    absolute
                    blue
                    d-inline-flex
                    align-items-center
                  "></div>
              <div class="circle-kwh">
                <span>{{ total_consumption }}</span>
                <span>&nbsp;kWh</span>
              </div>
              <div class="circle-kwh-legend">
                {{ $t("Accumulated Consumption") }}
                <div class="info-btn">
                  <b-button class="btn-sm temp-info-btn" style="bottom: -60px" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.left="
                      $t(
                        'Accumulated energy consumption in the period selected'
                      )
                    " variant="none">
                    <feather-icon class="temp-info-icon" icon="AlertCircleIcon" />
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
          <hr v-if="this.$roles.isAdmin(this.user) && hasDefrostSensor" />
          <b-row v-if="this.$roles.isAdmin(this.user) && hasDefrostSensor">
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon-potencia">
                  <img src="@/assets/images/icons/potencia.png" alt="" />
                </span>
                <span class="text-int"> {{ $t("Potency") }} </span>
              </div>
            </b-col>
            <b-col lg="6">
              <span class="graph-dot-blue"></span>
              <span class="graph-legend">{{ $t("Potency") }}</span>
              <div v-if="isLoadingPotency" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
              <div :style="isLoadingPotency ? 'display:none':'display:block'">
                <device-info-performace-potencia />
              </div>
            </b-col>
            <b-col lg="3" class="kwh-right">
              <div class="circle-kwh">
                <span>{{ avg_potency }}</span>
                <span>&nbsp;W</span>
              </div>
              <div class="circle-kwh-legend">
                {{ $t("Average in the period") }}
              </div>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/vapor.png" alt="" />
                </span>
                <span class="text-int"> {{ $t("Equipment Stress") }} </span>
              </div>
            </b-col>
            <b-col lg="6">
              <span class="graph-dot-blue"></span>
              <span class="graph-legend">{{
                $t("Equipment Effort Rate")
              }}</span>
              <div v-if="isLoadingPerformEffort" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
              <div :style="isLoadingPerformEffort ? 'display:none':'display:block'">
                <device-info-performance-effort @updateBarsEffort="updateSliders" />
              </div>
              <div class="mb-1">
                <span class="graph-dot-blue"></span>
                <span class="graph-legend">{{
                  $t("Equipment Effort Rate")
                }}</span>
              </div>
              <vue-slider v-model="value" :direction="direction" class="mb-2 p-0 range-temp" />
            </b-col>
            <b-col lg="3" class="temp-alert">
              <div>
                <div class="icon-status">
                  <img src="@/assets/images/icons/ok.png" alt="" />
                </div>
                <span class="text-alert">OK</span>
                <span class="text-estado">{{ $t("Device Status") }}</span>
              </div>
            </b-col>
          </b-row>
          <hr v-if="this.$roles.isAdmin(this.user) && hasDefrostSensor" />
          <b-row v-if="this.$roles.isAdmin(this.user) && hasDefrostSensor">
            <b-col md="12" lg="6" class="device-vent">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/freeze.png" alt="" />
                </span>
                <span class="text-int"> {{ $t("Defrosting Equipment") }} </span>
              </div>
              <div v-if="isLoadingDefrostEquipment_2" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
              <div :style="isLoadingDefrostEquipment_2 ? 'display:none':''">
                <b-card no-body>
                  <b-card-body>
                    <!-- <device-info-performace-cons-energ /> -->
                    <!-- <span class="graph-dot-blue"></span>
                    <span class="graph-legend">{{
                      $t("Probe Temperature")
                    }}</span> -->
                    <device-info-performace-descongelar />
                  </b-card-body>
                </b-card>
              </div>
            </b-col>
            <b-col md="12" lg="6">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/freeze.png" alt="" />
                </span>
                <span class="text-int"> {{ $t("Defrosting Equipment") }} </span>
              </div>
              <div v-if="isLoadingDefrostEquipment_2" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
            <div :style="isLoadingDefrostEquipment_2 ? 'display:none':''">
              <b-card no-body>
                <b-card-body>
                  <vue-apex-charts type="line" height="400" :options="revenueReport.chartOptions"
                    :series="revenueReport.series" />
                </b-card-body>
              </b-card>
            </div>
            </b-col>
          </b-row>
          <hr v-if="this.$roles.isAdmin(this.user) && hasDefrostSensor" />
          <b-row v-if="this.$roles.isAdmin(this.user) && hasDefrostSensor">
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon-energy">
                  <img src="@/assets/images/icons/freeze.png" alt="" />
                </span>
                <span class="text-int"> {{ $t("Defrosting Equipment") }} </span>
              </div>
            </b-col>
            <div v-if="isLoadingDefrostEquipment_3" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
            <b-col :style="isLoadingDefrostEquipment_3 ? 'display:none':''" lg="6">
              <!-- <device-info-performace-cons-energ /> -->
              <device-info-performace-descongelar-duracao />
            </b-col>
          </b-row>
          <hr v-if="this.isNotClient" />
          <b-row v-if="this.isNotClient">
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/vapor.png" alt="" />
                </span>
                <span class="text-int">
                  {{ $t("Evaporator Overheating") }}
                </span>
              </div>
            </b-col>
            <div v-if="isLoadingEvapOver" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
              <b-col :style="isLoadingEvapOver ? 'display:none':''" lg="6">
                <device-info-envolvente-sobreaquec />
              </b-col>
            <!-- <b-col lg="3" class="sobreaquec-right">
              <vue-gauge
                :refid="'gauge-performace'"
                :options="{
                  chartWidth: 200,
                  hasNeedle: true,
                  needleColor: black,
                  needleValue: 20,
                  arcColors: ['#28c76f', '#ff9f43', '#e51a4b'],
                  arcDelimiters: [30, 70],
                  rangeLabel: ['', ''],
                }"
              ></vue-gauge>
              <div class="info-btn">
                <b-button
                  class="btn-sm temp-info-btn"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-tooltip.hover.left="
                    'Custo e tempo total que o modo de conservação esteve ativo após concluído um ciclo. Para máx. eficiência reduza este valor.'
                  "
                  variant="none"
                >
                  <feather-icon class="alert-info-icon red" icon="BellIcon" />
                  <span class="notification">1</span>
                </b-button>
              </div>
            </b-col> -->
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  VBTooltip,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import DeviceInfoEnvolventeSobreaquec from "../Envolvente/DeviceInfoEnvolventeSobreaquec.vue";
import DeviceInfoPerformacePotencia from "../../device/Performace/DeviceInfoPerformacePotencia.vue";
import DeviceInfoPerformaceConsEnerg from "../Performace/DeviceInfoPerformaceConsEnerg.vue";
import DeviceInfoPerformaceEvap from "./DeviceInfoPerformaceEvap.vue";
import DeviceInfoPerformaceTop from "./DeviceInfoPerformaceTop.vue";
import DeviceInfoEnvolventeTempAmb from "../Envolvente/DeviceInfoEnvolventeTempAmb.vue";
import DeviceInfoPerformanceEffort from "./DeviceInfoPerformanceEffort.vue";
import VueGauge from "vue-gauge";
import VueApexCharts from "vue-apexcharts";
import VueSlider from "vue-slider-component";
import DeviceInfoPerformaceDescongelar from "./DeviceInfoPerformaceDescongelar.vue";
import DeviceInfoPerformaceDescongelarDuracao from "./DeviceInfoPerformaceDescongelarDuracao.vue";
import store from "@/store/index";
import moment from "moment-timezone";
export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    VBTooltip,
    vSelect,
    DeviceInfoEnvolventeSobreaquec,
    DeviceInfoPerformacePotencia,
    DeviceInfoPerformaceConsEnerg,
    DeviceInfoPerformaceEvap,
    DeviceInfoPerformaceTop,
    VueGauge,
    VueApexCharts,
    VueSlider,
    DeviceInfoEnvolventeTempAmb,
    DeviceInfoPerformanceEffort,
    DeviceInfoPerformaceDescongelar,
    DeviceInfoPerformaceDescongelarDuracao,
    BSpinner
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      value: 10,
      data: {},
      revenueReport: {
        series: [],
        chartOptions: {
          chart: {
            zoom: {
              enabled: true,
              type: "xy",
              resetIcon: {
                offsetX: -10,
                offsetY: 0,
                fillColor: "#fff",
                strokeColor: "#37474F",
              },
              selection: {
                background: "#90CAF9",
                border: "#0D47A1",
              },
            },
            toolbar: {
              show: true,
              position: "top",
              horizontalAlign: "right",
              tools: {
                download: true,
                selection: true,
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true | '<img src="/static/icons/reset.png" width="20">',
                customIcons: []
              },
              export: {
                csv: {
                  filename: "",
                  columnDelimiter: ',',
                  headerCategory: 'category',
                  headerValue: 'value',
                  dateFormatter(timestamp) {
                    return new Date(timestamp).toDateString()
                  }
                },
                svg: {
                  filename: "",
                },
                png: {
                  filename: "",
                }
              },
            autoSelected: 'zoom' 
            },
          },
          markers: {
            strokeWidth: 1,
            strokeOpacity: 1,
            strokeColors: "#2f79bb",
            colors: "#2f79bb",
          },
          colors: ["#2f79bb", "#4faef2"],
          legend: {
            show: true,
            position: "top",
            horizontalAlign: "left",
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            enabled: true,
            x: {
              show: true,
              format: "dd MMM HH:mm",
              formatter: undefined,
            },
          },
          xaxis: {
            categories: [],
            type: "datetime",
            labels: { format: "HH:mm", datetimeUTC: false },
          },
          yaxis: {
            labels: {
              formatter: function (val, index) {
                return val.toFixed(0) + "ºC";
              },
            },
          },
          noData: {
            text: "No Data",
          },
        },
      },
    };
  },
  methods: {
    updateSliders(data) {
      if (data["Equipment Stress"] < 60) this.value = 10;
      else if (data["Equipment Stress"] >= 60 && data["Equipment Stress"] < 90)
        this.value = 50;
      else this.value = 90;
    },
    getData() {
      this.$store.commit("devices/SET_LOADING_STATE_START_GRAPH",'loading_defrost_equipment_2');
      const labelFormat = this.timeInterval > 24 ? 'dd/MM' : 'HH:mm';
      this.revenueReport.series = []
      let deviceId = this.$route.params.id;
      const metricsEndpoint = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=internal_temperature`;
      const metricsEndpoint2 = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-data-and-label/?device=${deviceId}&interval=${this.timeInterval}&metric_field=probe_temp`;

      this.$http.get(metricsEndpoint).then((res) => {
        let options = { ...this.revenueReport.chartOptions };
        let timestampCategories = res.data.categories.map((cat) => {
          return moment(new Date(cat)).tz(this.deviceTZ).format();
        });
        options.xaxis.categories = timestampCategories;
        this.revenueReport.chartOptions = options;

        this.revenueReport.series.push({
          name: this.$t("Internal Temperature"),
          data: res.data.data.map((el) => {
            return Number(el.toFixed(2));
          }),
        });
        this.$store.commit("devices/SET_LOADING_STATE_DONE_GRAPH",'loading_defrost_equipment_2');
      });

      this.$http.get(metricsEndpoint2).then((res) => {
        let options = { ...this.revenueReport.chartOptions };
        let timestampCategories = res.data.categories.map((cat) => {
          return moment(new Date(cat)).tz(this.deviceTZ).format();
        });
        options.xaxis.labels.format = labelFormat;
        options.xaxis.categories = timestampCategories;
        this.revenueReport.chartOptions = options;

        this.revenueReport.series.push({
          name: this.$t("Probe Temperature"),
          data: res.data.data.map((el) => {
            return Number(el.toFixed(2));
          }),
        });
      });
    },
  },
  computed: {
    isLoadingPotency(){
      return this.$store.state.devices.graph_loadings.loading_potency
    },
    isLoadingPerformEffort(){
      return this.$store.state.devices.graph_loadings.loading_perform_effor
    },
    isLoadingEvapOver(){
      return this.$store.state.devices.graph_loadings.loading_evaporator_overheating
    },
    isLoadingDefrostEquipment_1(){
      return this.$store.state.devices.graph_loadings.loading_defrost_equipment_1
    },
    isLoadingDefrostEquipment_2(){
      return this.$store.state.devices.graph_loadings.loading_defrost_equipment_2
    },
    isLoadingDefrostEquipment_3(){
      return this.$store.state.devices.graph_loadings.loading_defrost_equipment_3
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = "rtl";
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = "ltr";
      return this.dir;
    },
    avg_potency() {
      return this.$store.state.devices.currentDevice.avg_potency
        ? this.$store.state.devices.currentDevice.avg_potency
        : 0;
    },
    total_consumption() {
      return this.$store.state.devices.current_total_consumption
    },
    timeInterval() {
      return this.$store.state.devices.timeInterval;
    },
    deviceTZ() {
      return this.$store.state.devices.currentDevice.timezone
        ? this.$store.state.devices.currentDevice.timezone
        : "Europe/Lisbon";
    },
    hasDefrostSensor() {
      return this.$store.state.devices.currentDevice.has_defrosting_sensor;
    },
    currentDevicerRoles() {
      return this.$store.state.devices.currentDevicerRoles
    },
    user(){
      return this.$store.state.users.currentUser
    },
    fileName(){
      let currentDevice = this.$store.state.devices.currentDevice
      let file_name = `EVAPORATOR_DEFROST_${currentDevice.model_code}-${currentDevice.name ? currentDevice.name : currentDevice.mac_number }`
      let options = { ...this.revenueReport.chartOptions };
      options.chart.toolbar.export.csv.filename = file_name
      options.chart.toolbar.export.svg.filename = file_name
      options.chart.toolbar.export.png.filename = file_name
      this.revenueReport.chartOptions = options;
    },
    isNotClient(){
      return !this.$roles.isClient(this.userData, this.currentDevicerRoles)
    }
  },
  watch: {
    timeInterval(newProp, oldProp) {
      this.getData();
    },
    fileName(){}
  },
  created() {
    // data
    this.getData();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>