<template>
  <div id="03">
    <b-row class="match-height">
      <b-col md="12" lg="12">
        <b-card no-body class="performace-device">
          <b-card-body>
            <div class="performace-top">
              <span class="performace-icon">
                <feather-icon icon="TrendingUpIcon" />
              </span>
              <span class="text-performace"> {{ $t("Performance") }} </span>
            </div>
          </b-card-body>
        </b-card>
        <b-card class="section-temp">
          <b-row>
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon-energy">
                  <img src="@/assets/images/icons/energy.png" alt="" />
                </span>
                <span class="text-int"> {{ $t("Energy Consumption") }} </span>
              </div>
            </b-col>
            <div v-if="isLoadingEnergyConsumption" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
            <b-col :style="isLoadingEnergyConsumption ? 'display:none':''" lg="6">
              <device-info-performace-cons-energ />
            </b-col>

            <b-col lg="3" class="kwh-right kwh-btn">
              <div class="circle-kwh-legend">
                <div class="info-btn-holding-mod">
                  <b-button class="btn-sm temp-info-btn" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.left="
                      $t(
                        'Accumulated energy consumption in the period selected'
                      )
                    " variant="none">
                    <feather-icon class="temp-info-icon" icon="AlertCircleIcon" />
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
          <hr v-if="this.$roles.isAdmin(this.userData)" />
          <b-row v-if="this.$roles.isAdmin(this.userData)">
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon-potencia">
                  <img src="@/assets/images/icons/potencia.png" alt="" />
                </span>
                <span class="text-int"> {{ $t("Potency") }} </span>
              </div>
            </b-col>
            <b-col lg="6">
              <span class="graph-dot-blue"></span>
              <span class="graph-legend">{{ $t("Potency") }}</span>
              <div v-if="isLoadingPotency" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
              <div :style="isLoadingPotency ? 'display:none':''">
                <device-info-performace-potencia />
              </div>
            </b-col>
            <b-col lg="3" class="kwh-right">
              <div class="circle-kwh">
                <span>{{ avg_potency }}</span>
                <span>&nbsp;W</span>
              </div>
              <div class="circle-kwh-legend">
                {{ $t("Average in the period") }}
              </div>
            </b-col>
          </b-row>
          <hr v-if="this.isNotClient" />
          <b-row v-if="this.isNotClient">
            <b-col lg="3">
              <div class="temp-left">
                <span class="img-icon">
                  <img src="@/assets/images/icons/vapor.png" alt="" />
                </span>
                <span class="text-int">
                  {{ $t("Evaporator Overheating") }}
                </span>
              </div>
            </b-col>
            <div v-if="isLoadingEvapOverHeat" class="text-center" style="height: 350px; align-content: center;">
                  <b-spinner variant="primary" key="primary" ></b-spinner>
              </div>
            <b-col :style="isLoadingEvapOverHeat ? 'display:none':''" lg="6">
              <device-info-envolvente-sobreaquec />
            </b-col>

          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BRow,
  BCol,
  BImg,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BLink,
  BFormGroup,
  VBTooltip,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import DeviceInfoEnvolventeSobreaquec from "../Envolvente/DeviceInfoEnvolventeSobreaquec.vue";
import DeviceInfoPerformacePotencia from "./DeviceInfoPerformacePotencia.vue";
import DeviceInfoPerformaceConsEnerg from "./DeviceInfoPerformaceConsEnerg.vue";
import DeviceInfoPerformaceTop from "./DeviceInfoPerformaceTop.vue";
import VueGauge from "vue-gauge";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
    BFormGroup,
    VBTooltip,
    vSelect,
    DeviceInfoEnvolventeSobreaquec,
    DeviceInfoPerformacePotencia,
    DeviceInfoPerformaceConsEnerg,
    DeviceInfoPerformaceTop,
    VueGauge,
    BSpinner
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      data: {},
    };
  },
  methods: {
  },
  computed: {
    isLoadingEnergyConsumption(){
      return this.$store.state.devices.graph_loadings_blast_chill.loading_energy_consumption
    },
    isLoadingPotency(){
      return this.$store.state.devices.graph_loadings.loading_potency
    },
    isLoadingEvapOverHeat(){
      return this.$store.state.devices.graph_loadings_blast_chill.loading_evaporator_overheating
    },
    avg_potency() {
      return this.$store.state.devices.currentDevice.avg_potency
        ? this.$store.state.devices.currentDevice.avg_potency
        : 0;
    },
    total_consumption() {
      return this.$store.state.devices.currentDevice.total_consumption
        ? this.$store.state.devices.currentDevice.total_consumption.toLocaleString(
          "default",
          {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          }
        )
        : 0;
    },
    currentDevicerRoles() {
      return this.$store.state.devices.currentDevicerRoles
    },
    userData() {
      return this.$store.state.users.currentUser
    },
    isNotClient(){
      return !this.$roles.isClient(this.userData, this.currentDevicerRoles)
    }
  },
  created() {
    // data
    this.$http.get("/analytics/data").then((response) => {
      this.data = response.data;
    });
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
