<template>
  <b-card no-body>
    <b-card-body>
      <!-- <vue-apex-charts
        type="scatter"
        height="400"
        :options="chartOptions"
        :series="series"
      /> -->
      <vue-apex-charts type="line" height="400" :options="chartOptions2" :series="series" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BButtonGroup,
  BCardTitle,
  BCardSubTitle,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
// import Ripple from "vue-ripple-directive";
import performaceEnergChartData from "./performaceEnergChartData";
import moment from "moment-timezone";
export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BButton,
    BButtonGroup,
    BCardTitle,
    BCardSubTitle,
    BBadge,
  },
  // directives: {
  //   Ripple,
  // },
  data() {
    return {
      chartOptions2: {
        colors: ["#2f79b7", "#4fa9ed"],
        chart: {
          height: 400,
          type: "bar",
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: true | '<img src="/static/icons/reset.png" width="20">',
              customIcons: []
            },
            export: {
              csv: {
                filename: "",
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                }
              },
              svg: {
                filename: "",
              },
              png: {
                filename: "",
              }
            },
            autoSelected: 'zoom' 
          },
        },
        fill: {
          type: "solid",
        },
        
        stroke: {
          curve: "straight",
          width: [0, 6]
        },
        xaxis: {
          categories: [],
          type: "datetime",
          labels: {
            format: "HH:mm",
            datetimeUTC: false,
          },
        },
        yaxis: {
          labels: {
            formatter: function (val, index) {
              let newVal = val / 1000;
              return newVal.toFixed(3).replace('.', ',') + " kWh";
              // if (val.toFixed(0) > 1000) return val.toFixed(0) + " kWh";
              // else return val.toFixed(0) + " Wh";
            },
          },
          tickAmount: 1,
        },
        noData: {
          text: "No Data",
        },
        tooltip: {
          shared: false,
          intersect: false,
          enabled: true,
          x: {
            show: true,
            format: "dd MMM HH:mm",
            formatter: undefined,
            labels: { datetimeUTC: false },
          },
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
        },
      },
      performaceEnergChartData,
      series: [],
    };
  },
  computed: {
    timeInterval() {
      return this.$store.state.devices.timeInterval;
    },
    deviceTZ() {
      return this.$store.state.devices.currentDevice.timezone
        ? this.$store.state.devices.currentDevice.timezone
        : "Europe/Lisbon";
    },
    fileName(){
      let currentDevice = this.$store.state.devices.currentDevice
      let file_name = `ENERGY_CONSUMPTION_${currentDevice.model_code}-${currentDevice.name ? currentDevice.name : currentDevice.mac_number }`
      let options = { ...this.chartOptions2 };
      options.chart.toolbar.export.csv.filename = file_name
      options.chart.toolbar.export.svg.filename = file_name
      options.chart.toolbar.export.png.filename = file_name
      this.chartOptions2 = options;
    }
  },
  watch: {
    timeInterval(newProp, oldProp) {
      this.getData();
      this.getTotalConsumption();
    },
    fileName(){}
  },
  methods: {
    getData() {
      const labelFormat = this.timeInterval > 24 ? 'dd/MM' : 'HH:mm';
      this.series = [];
      let deviceId = this.$route.params.id;
      let options = { ...this.chartOptions2 };
      let metricsEndpoint = `${process.env.VUE_APP_API_URL}api/metrics/time-series/get-energy-consumption-generic/?device=${deviceId}&interval=${this.timeInterval}&metric_field=energy_consumption_all`;

      try{
        this.$http.get(metricsEndpoint).then((res) => {
        let processed = [];
        let processedTrendLine = [];

        

        let timestampCategories = res.data.categories.map((cat) => {
          return moment(new Date(cat)).tz(this.deviceTZ).format();
        });
        
        options.xaxis.categories = timestampCategories;
        options.xaxis.labels.format = labelFormat;
        this.chartOptions2 = options;

        for (let index = 0; index < timestampCategories.length; index += 1) {
          processed.push([
            timestampCategories[index],
            Number(res.data.data[index].toFixed(2)),
          ]);
        }
        this.series.push({
          name: this.$t("Energy Consumption"),
          type: "bar",
          data: processed,
        });
        let first = (processed[0][1] + processed[1][1]) / 2;
        let last =
          (processed[processed.length - 1][1] +
            processed[processed.length - 2][1]) /
          2;
        processedTrendLine.push([processed[0][0], first]);
        processedTrendLine.push([processed[processed.length - 1][0], last]);
        this.series.push({
          name: this.$t("Trend"),
          type: "line",
          data: processedTrendLine,
        });
        this.getTotalConsumption(res.data.total)
      });
      }
      catch (error) {
        options.noData.text = "No Data";
      }
     
    },
    getTotalConsumption(total_consumption) {
      this.$store.commit(
          "devices/SET_DEVICE_TOTAL_CONSUMPTION",
          total_consumption.toFixed(3).replace('.', ',')
        );
    },
  },
  created() {
    this.getData();
  },
};
</script>